@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer components {
  .textfield {
    @apply p-2 rounded-sm sm:text-sm border border-gray-300 focus:ring-blue-500 focus:border-blue-500 hover:border-gray-400 hover:bg-gray-50;
  }

  .textlink {
    @apply text-blue-600 hover:text-blue-900;
  }

  .react-date-picker .react-date-picker__wrapper {
    border-width: 0;
  }

  .react-datetime-picker .react-datetime-picker__wrapper {
    border-width: 0;
  }
}
